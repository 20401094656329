import React, { useState } from "react"
import { graphql, Link } from "gatsby"

// Pagetransition
import Transition from '../components/transition/transition'
import shakeString from '../helpers/shakeString'

import Footer from '../components/layout/footer'
import LazySizes from '../components/image/lazysizes'

import "./info.scss"

export default({ data }) => {
  
  let {
    infotext,
    infodetails,
    infodetailsDe,
    contact,
    address,
    // team, // TODO: Where to put??
    image
  } = data.wpgraphql.pages.nodes[0].info;

  const [ state ] = useState({
    infotext: shakeString(infotext),
  })

  return (
      <Transition>
        <div className="info__wrapper">
          <div className="info__text">
            <div className="info__infotext">
              <p dangerouslySetInnerHTML={{__html: `${state.infotext}<br><br>`}} />
              <div className="info__more">
                <div className="info__details">
                  <div>
                    {
                      contact.map((item, i) => (
                        <div key={i} className="info__details__listItem">
                          <div dangerouslySetInnerHTML={{__html: item.label}} />
                          <div dangerouslySetInnerHTML={{__html: item.text}} />
                        </div>
                      ))
                    }
                    <br/>
                    <br/>
                  </div>
                  <div>
                    {/* <div>
                
                      {address}
                    </div> */}
                    <div dangerouslySetInnerHTML={{__html: `${address}<br />`}} />
                    <ul>
                      <li>
                        <Link to={'/list?search_term=publication'}>Publications</Link>
                      </li>
                      <li>
                        <Link to={'/list?search_term=lecture'}>Lectures</Link>
                      </li>
                      <li>
                        <Link to={'/list?search_term=award'}>Awards</Link>
                      </li>
                      {/* <li>
                        <Link to={'/list'}>See Presentations</Link>
                      </li> */}
                      <li>
                        <Link to={'/list?search_term=exhibition'}>Exhibitions</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="info__image__frame info__image__portrait">
                    <LazySizes image={image} preload={true} />
                  </div>
                </div>
                <div className="info__description">
                  <div dangerouslySetInnerHTML={{__html: `${infodetails}<br />`}} />
                  <div dangerouslySetInnerHTML={{__html: `${infodetailsDe}<br />`}} />
                </div>
              </div>
              <Footer />
            </div>
            {/* <div className="info__image__frame info__image__portrait">
              <LazySizes image={image} preload={true} />
            </div> */}
          </div>
        </div>
      </Transition>
)}

export const infoQuery = graphql`
  query infoQuery {
    wpgraphql{
      categories{
        nodes {
          slug
        }
    }
    pages(where: {name: "info"}) {
        nodes {
          title
          id
          slug
          info {
            infotext
            infodetails
            infodetailsDe
            contact{
              label
              text
            }
            address
            team
            image {
              title
            
              sourceUrl
              altText
              mediaDetails {
                sizes{
                  sourceUrl
                  width
                  height
                }
              }
            }
          }
        }
      }
    }
  }
`