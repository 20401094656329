import React, { useState, useRef } from "react"
import { StaticQuery, graphql } from 'gatsby'
import './footer.scss'

const Footer = () => {

    const [ isOpen, setIsOpen ] = useState(false)
    const imprintRef = useRef()

    const openImprint = () => {
        setIsOpen(!isOpen)
        setTimeout(() => {
            imprintRef.current.scrollIntoView({
                behavior: 'smooth'
            })
        }, 2)
    }

    return(
        <StaticQuery
            query={graphql`
                query ImprintQuery {
                    wpgraphql{
                        pages(where: {name: "info"}) {
                            nodes{
                                info {
                                    imprint
                                }
                            }
                        }
                    }
                }   
            `}
            render={data => (
            <footer className="footer">
                <div className="footerButton" onClick={openImprint} onKeyDown={openImprint} role="button" tabIndex={0} aria-label="Imprint" ref={imprintRef} dangerouslySetInnerHTML={{__html: "Imprint" }} />
                {
                  isOpen ?
                  <div className="footerWrapper">
                      <div dangerouslySetInnerHTML={{__html: data.wpgraphql.pages.nodes[0].info.imprint }} />
                  </div>
                  : false
                }
            </footer>
        )} />
    )
}

export default Footer